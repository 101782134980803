/* .nav-container {
    width: 500px;
    max-width: 500px;
    margin: 0px;
    padding: 0px;
    position: fixed;
    top: 90%;
    left: 50%;
    // transform: translate(-50%, -50%);
    @apply w-[500px] -translate-x-1/2 transition-all m-0 p-0 fixed top-[90%] left-[50%];
} */

/* .nav-bar {
    background: rgba(37, 40, 45, 0.21);
    border-radius: 61px;
    margin: 0px;
    padding: 0px;
    box-shadow: 0 4px 30px rgba(0, 0, 0, 0.1);
    backdrop-filter: blur(5px);
    -webkit-backdrop-filter: blur(5px);
    border: 1px solid rgba(255, 255, 255, 0.3);

    @apply bg-shark/25 rounded-full m-0 p-0 shadow-sm backdrop-blur border border-shark-200/30;
} */

.nav-list {
    list-style-type: none;
    display: flex;
    justify-content: space-between;
    margin: 0px;
    color: white;
    padding-left: 0;
    padding: 5px;
}

/* .item-lbl {
    color: #EFEDE7;
    border-radius: 60px;
    padding: 10px 20px;
    cursor: pointer;
    transition: color 0.15s ease-in;
    flex-basis: 100px;
    text-align: center;
    z-index: 2;
    @apply bg-shark/25 rounded-full py-[10px] px-[20px] cursor-pointer transition-colors flex-1 text-center z-10;
} */

.active {
    background: #EFEDE7;
    color: #25282D;
}

input[type="radio"] {
    display: none;
}

input[type="radio"]:checked+label {
    color: #25282D;
}


/* input[id="inicio"]:checked~.glider {
    transform: translateX(0);
}

input[id="equipo"]:checked~.glider {
    transform: translateX(100%);
}

input[id="trabajo"]:checked~.glider {
    transform: translateX(200%);
}

input[id="contacto"]:checked~.glider {
    transform: translateX(300%);
} */

.glider {
    position: absolute;
    display: flex;
    height: 44px;
    background-color: #EFEDE7;
    z-index: 1;
    border-radius: 99px;
    transition: 0.25s ease-out;
}