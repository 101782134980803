#left-spacer {
    background-color: #25282d;
    opacity: 0.5;
    background-size: 30px 30px;
    background-image: repeating-linear-gradient(45deg, #efede7 0, #efede7 1px, #25282d 0, #25282d 50%);
}

#right-spacer {
    background-color: #25282d;
    opacity: 0.5;
    background-size: 30px 30px;
    background-image: repeating-linear-gradient(135deg, #efede7 0, #efede7 1px, #25282d 0, #25282d 50%);
}